<template>
    <div class="app">
        <router-view></router-view>
    </div>
</template>

<style>
    *,
    *::before,
    *::after{
        box-sizing: border-box;
    }
    *{
        margin: 0;
        padding: 0;
    }
    body{
        font-family: 'Bitter', serif;
    }
    a, button{
        color: #514E47;
        font-family: 'Bitter', serif;
    }
    p, span{
        color: #514E47;
    }
    .popup__heading{
        color: #404141;
    }
    .popup__inner{
        position: fixed;
        display: flex;
        z-index: 1000;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background: rgba(14, 14, 14, 0.23);
        backdrop-filter: blur(2px);
    }
    .popup-close{
        position: absolute;
        top: 30px;
        right: 30px;
        height: 20px;
        width: 20px;
    }
    .popup-close::before{
        position: absolute;
        content: "";
        height: 2px;
        width: 25px;
        background-color: #514E47;
        top: 12px;
        right: 0;
        transform: rotate(45deg);
    }
    .popup-close::after{
        position: absolute;
        content: "";
        height: 2px;
        width: 25px;
        background-color: #514E47;
        top: 12px;
        right: 0;
        transform: rotate(-45deg);
    }
    .popup-form{
        border-radius: 2px;
        position: relative;
        background-color: #F0EEEB;
        padding: 70px 140px;
        width: 100%;
        max-width: 620px;
    }
    .popup-heading{
        text-align: center;
        font-size: 24px;
        color: #514E47;
        font-weight: 400;
    }
    .popup-input{
        width: 100%;
        padding: 10px 20px;
        border-radius: 34px;
        color: #878787;
    }
    button{
        background: none;
        outline: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
    }
    .btn{
        text-transform: uppercase;
        border: 1px solid #514E47;
        border-radius: 40px;
        color: #514E47;
        font-weight: 700;
        transition: all linear .1s;
    }
    .btn:hover{
        box-shadow: 0 8px #E1DEDA;
    }
    .container{
        max-width: 1260px;
        padding: 0 15px;
        margin: 0 auto;
    }
    .gross-container{
        padding: 0 75px;
        margin: 0 auto;
    }
    input{
        outline: none;
        border: none;
        font-family: 'Bitter', serif;
        font-size: 16px;
    }
    .input{
        padding: 12px 20px;
        border-radius: 35px;
        background-color: #FBFBFB;
        color: #878787;
        font-size: 16px;
        width: 100%;
    }
    .heading{
        font-size: 24px;
        font-weight: 500;
        text-transform: uppercase;
        color: #514E47;
    }
    /* Чекбокс */
    .checkbox__wrapper{
        display: flex;
        align-items: center;
    }
    .checkbox__wrapper:hover .checkbox__ico{
        background-color: #514E47;
    }
    .checkbox__ico{
        position: relative;
        display: inline-block;
        vertical-align: baseline;
        height: 22px;
        width: 22px;
        margin-right: 20px;
        margin-bottom: -1px;
        border-radius: 50%;
        border: 1px solid #514E47;
        cursor: pointer;
        transition: all linear .2s;
    }
    .checkbox__input{
        display: none;
    }
    .checkbox_select{
        background-color: #514E47;
        transition: all linear .1s;
    }
    .checkbox_select::before{
        position: absolute;
        content: "";
        height: 12px;
        width: 2px;
        top: 4px;
        right: 7px;
        transform: rotate(35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .checkbox_select::after{
        position: absolute;
        content: "";
        height: 8px;
        width: 2px;
        bottom: 4px;
        left: 6px;
        transform: rotate(-35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .checkbox__label{
        cursor: pointer;
    }
    /* Альтернативный чекбокс */
    .alt-checkbox__wrapper{
        display: flex;
        align-items: center;
    }
    .alt-checkbox__ico{
        border: none;
        background-color: #E1DEDA;
        height: 24px;
        width: 24px;
        transition: all linear .2s;
    }
    .alt_checkbox_select{
        transition: all linear .2s;
    }
    .alt_checkbox_select::before{
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        border-radius: 50%;
        top: 5px;
        left: 5px;
        background-color: #878787;
        transition: all linear .2s;
    }

    /* Радиокнопки */
    .controls-radio__btn:checked + .controls-radio__label::after{
        transform: scale(1);
    }
    .controls-radio__label{
        position: relative;
        display: inline-block;
        padding-left: 40px;
        cursor: pointer;
    }
    .controls-radio__label::before{
        content: "";
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: transparent;
        border: 1px solid #514E47;
        left: 0;
        top: 0;
    }
    .controls-radio__label::after{
        content: "";
        position: absolute;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background-color: #514E47;
        left: 4px;
        top: 4px;
        transition: all linear .2s;
        transform: scale(0);
    }
    .controls-radio__btn{
        display: none;
    }
    .invalid{
        border: 1px solid red;
    }
    .loading{
        pointer-events: none;
        opacity: .5;
    }
    @media (max-width: 600px){
        .container{
            padding-left: 10px;
            padding-right: 10px;
        }
        .checkbox__label{
            max-width: 230px;
        }
        .popup-form{
            padding: 30px 55px;
        }
        .btn:hover{
            box-shadow: none;
        }
    }
</style>
