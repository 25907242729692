import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'client-main-page',
    component: () => import('@/components/client-pages/pages/Main-page')
  },
  {
    path: '/client-cart',
    name: 'client-cart',
    component: () => import('@/components/client-pages/pages/Cart-page-client')
  },
  {
    path: '/gross-main-page',
    name: 'gross-main-page',
    component: () => import('@/components/gross-pages/pages/Gross-main-page')
  },
  {
    path: '/account-main-page',
    name: 'account-main-page',
    component: () => import('@/components/client-pages/account/pages/Account-main-page')
  },
  {
    path: '/advantages-page',
    name: 'advantages-page',
    component: () => import('@/components/client-pages/pages/Advantages-page')
  },
  {
    path: '/gross-cart-page',
    name: 'gross-cart-page',
    component: () => import('@/components/gross-pages/pages/Gross-cart-page')
  },
  {
    path: '/admin-page',
    name: 'admin-page',
    component: () => import('@/components/admin-pages/pages/Admin-main')
  },
  {
    path: '/admin-orders',
    name: 'admin-orders',
    component: () => import('@/components/admin-pages/pages/Admin-orders-page')
  },
  {
    path: '/admin-partners',
    name: 'admin-partners',
    component: () => import('@/components/admin-pages/pages/Admin-partners-page')
  },
  {
    path: '/admproduct',
    name: 'admproduct',
    component:()=> import('@/components/admin-pages/pages/Admin-product') 
  },
  {
    path: '/delivery',
    name: 'delivery',
    component:()=> import('@/components/client-pages/pages/Delivery-page') 
  },
  {
    path: '/confedential',
    name: 'confedential',
    component:()=> import('@/components/client-pages/pages/Confedential-page') 
  },
  {
    path: '/oferta',
    name: 'oferta',
    component:()=> import('@/components/client-pages/pages/Oferta') 
  },
  {
    path: '/success',
    name: 'success',
    component:()=> import('@/components/client-pages/pages/Success-page') 
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
