import { createStore } from 'vuex'
import axios from 'axios';

export default createStore({
    state: {
        products: [
            // {
            //     id: 331,
            //     isPopular: true,
            //     name: "101 красная роза",
            //     price: 4390,
            //     category: "Авторские букеты",
            //     structure: "француская роза ,гербера,гладиолус,роза кустовая,маттиола",
            //     url: "50984d25-5aee-466a-8def-9c36d6585ea1.jpg",
            //     url1: "",
            //     url2: "",
            //     url3: "",
            //     quantity: 1,
            //     likes: 0,
            //     meta: "мягкая игрушка ,доставка цветов,пионы,маттиола,цветылюбимой",
            //     createdAt: "2023-07-31T12:48:50.000Z",
            //     updatedAt: "2023-07-31T12:50:23.000Z"
            //     },
            //     {
            //     id: 330,
            //     isPopular: true,
            //     name: " 51 краская роза",
            //     price: 6990,
            //     category: "Авторские букеты",
            //     structure: "Герберы, диантусы, гладиолусы, бувардия, аммимаус, французкая роза",
            //     url: "bd7b0e48-3309-486f-8620-e8d35008da86.jpg",
            //     url1: "",
            //     url2: "",
            //     url3: "",
            //     quantity: 1,
            //     likes: 0,
            //     meta: "мягкая игрушка ,доставка цветов,пионы,маттиола,цветылюбимой",
            //     createdAt: "2023-07-31T12:47:49.000Z",
            //     updatedAt: "2023-07-31T12:50:29.000Z"
            //     },
            //     {
            //     id: 329,
            //     isPopular: true,
            //     name: "25 красных роз",
            //     price: 6990,
            //     likes: 0,
            //     category: "Авторские букеты",
            //     structure: "Роза белая,диантусы,фрезия,лилия,маттиола",
            //     url: "1726b879-c47f-4274-8dca-f04001ef6c45.jpg",
            //     url1: "",
            //     url2: "",
            //     url3: "",
            //     quantity: 1,
            //     meta: "мягкая игрушка ,доставка цветов,пионы,маттиола,цветылюбимой",
            //     createdAt: "2023-07-31T12:46:45.000Z",
            //     updatedAt: "2023-07-31T12:50:35.000Z"
            //     },
                // {
                //     id: 328,
                //     isPopular: true,
                //     name: "101 красная роза",
                //     price: 4390,
                //     category: "Авторские букеты",
                //     structure: "француская роза ,гербера,гладиолус,роза кустовая,маттиола",
                //     url: "50984d25-5aee-466a-8def-9c36d6585ea1.jpg",
                //     url1: "",
                //     url2: "",
                //     url3: "",
                //     quantity: 1,
                //     likes: 0,
                //     meta: "мягкая игрушка ,доставка цветов,пионы,маттиола,цветылюбимой",
                //     createdAt: "2023-07-31T12:48:50.000Z",
                //     updatedAt: "2023-07-31T12:50:23.000Z"
                //     },
                //     {
                //     id: 327,
                //     isPopular: true,
                //     name: " 51 краская роза",
                //     price: 6990,
                //     category: "Авторские букеты",
                //     structure: "Герберы, диантусы, гладиолусы, бувардия, аммимаус, французкая роза",
                //     url: "bd7b0e48-3309-486f-8620-e8d35008da86.jpg",
                //     url1: "",
                //     url2: "",
                //     url3: "",
                //     quantity: 1,
                //     likes: 0,
                //     meta: "мягкая игрушка ,доставка цветов,пионы,маттиола,цветылюбимой",
                //     createdAt: "2023-07-31T12:47:49.000Z",
                //     updatedAt: "2023-07-31T12:50:29.000Z"
                //     },
                //     {
                //     id: 326,
                //     isPopular: true,
                //     name: "25 красных роз",
                //     price: 6990,
                //     category: "Авторские букеты",
                //     structure: "Роза белая,диантусы,фрезия,лилия,маттиола",
                //     url: "1726b879-c47f-4274-8dca-f04001ef6c45.jpg",
                //     url1: "",
                //     url2: "",
                //     url3: "",
                //     quantity: 1,
                //     likes: 0,
                //     meta: "мягкая игрушка ,доставка цветов,пионы,маттиола,цветылюбимой",
                //     createdAt: "2023-07-31T12:46:45.000Z",
                //     updatedAt: "2023-07-31T12:50:35.000Z"
                //     },
                //     {
                //         id: 325,
                //         isPopular: true,
                //         name: "101 красная роза",
                //         price: 4390,
                //         category: "Авторские букеты",
                //         structure: "француская роза ,гербера,гладиолус,роза кустовая,маттиола",
                //         url: "50984d25-5aee-466a-8def-9c36d6585ea1.jpg",
                //         url1: "",
                //         url2: "",
                //         url3: "",
                //         quantity: 1,
                //         likes: 0,
                //         meta: "мягкая игрушка ,доставка цветов,пионы,маттиола,цветылюбимой",
                //         createdAt: "2023-07-31T12:48:50.000Z",
                //         updatedAt: "2023-07-31T12:50:23.000Z"
                //         },
                //         {
                //         id: 324,
                //         isPopular: true,
                //         name: " 51 краская роза",
                //         price: 6990,
                //         category: "Авторские букеты",
                //         structure: "Герберы, диантусы, гладиолусы, бувардия, аммимаус, французкая роза",
                //         url: "bd7b0e48-3309-486f-8620-e8d35008da86.jpg",
                //         url1: "",
                //         url2: "",
                //         url3: "",
                //         quantity: 1,
                //         likes: 0,
                //         meta: "мягкая игрушка ,доставка цветов,пионы,маттиола,цветылюбимой",
                //         createdAt: "2023-07-31T12:47:49.000Z",
                //         updatedAt: "2023-07-31T12:50:29.000Z"
                //         },
                //         {
                //         id: 323,
                //         isPopular: true,
                //         name: "25 красных роз",
                //         price: 6990,
                //         category: "Авторские букеты",
                //         structure: "Роза белая,диантусы,фрезия,лилия,маттиола",
                //         url: "1726b879-c47f-4274-8dca-f04001ef6c45.jpg",
                //         url1: "",
                //         url2: "",
                //         url3: "",
                //         quantity: 1,
                //         likes: 0,
                //         meta: "мягкая игрушка ,доставка цветов,пионы,маттиола,цветылюбимой",
                //         createdAt: "2023-07-31T12:46:45.000Z",
                //         updatedAt: "2023-07-31T12:50:35.000Z"
                //         }
        ],
        popularProducts: [],
        currentProduct: {},
        mainProductPhoto: '',
        cart: [],
        sortDirection: '',
        currentGrossProduct: {},
        mainGrossProductPhoto: '',
        grossDiscount: 0,
        isBurgerOpened: false,
        grossCart: [
            // {
            //     id: 331,
            //     isPopular: true,
            //     name: "101 красная роза",
            //     price: 4390,
            //     category: "Авторские букеты",
            //     structure: "француская роза ,гербера,гладиолус,роза кустовая,маттиола",
            //     url: "ec655b06-8371-4b20-a98a-33b1352e9615.jpg",
            //     url1: "",
            //     url2: "",
            //     url3: "",
            //     cartQty: 100,
            //     quantity: 100,
            //     remains: 100,
            //     orderQty: 0,
            //     meta: "мягкая игрушка ,доставка цветов,пионы,маттиола,цветылюбимой",
            //     createdAt: "2023-07-31T12:48:50.000Z",
            //     updatedAt: "2023-07-31T12:50:23.000Z"
            //     },
            //     {
            //     id: 330,
            //     isPopular: true,
            //     name: " 51 краская роза",
            //     price: 6990,
            //     category: "Авторские букеты",
            //     structure: "Герберы, диантусы, гладиолусы, бувардия, аммимаус, французкая роза",
            //     url: "ec655b06-8371-4b20-a98a-33b1352e9615.jpg",
            //     url1: "",
            //     url2: "",
            //     url3: "",
            //     cartQty: 50,
            //     quantity: 50,
            //     remains: 50,
            //     orderQty: 0,
            //     meta: "мягкая игрушка ,доставка цветов,пионы,маттиола,цветылюбимой",
            //     createdAt: "2023-07-31T12:47:49.000Z",
            //     updatedAt: "2023-07-31T12:50:29.000Z"
            //     },
                // {
                // id: 332,
                // isPopular: true,
                // name: " 51 краская роза",
                // price: 6990,
                // category: "Авторские букеты",
                // structure: "Герберы, диантусы, гладиолусы, бувардия, аммимаус, французкая роза",
                // url: "bd7b0e48-3309-486f-8620-e8d35008da86.jpg",
                // url1: "",
                // url2: "",
                // url3: "",
                // quantity: 50,
                // remains: 0,
                // meta: "мягкая игрушка ,доставка цветов,пионы,маттиола,цветылюбимой",
                // createdAt: "2023-07-31T12:47:49.000Z",
                // updatedAt: "2023-07-31T12:50:29.000Z"
                // },
        ],
        grossShops: [
            {
                id: 1,
                isShopSelected: false,
                address: 'Адрес 1',
                cart: []
            },
            {
                id: 2,
                isShopSelected: false,
                address: 'Адрес 2',
                cart: []
            },
        ],
        grossProducts: [
            {
                id: 1,
                name: '',
                quantity: 0,
                isStock: 0,
                url: "",
                price: 0,
            },
            // {
            //     id: 2,
            //     name: 'Роза эквадор красная',
            //     quantity: 149,
            //     isStock: 2,
            //     measure: '1 шт',
            //     url: "50984d25-5aee-466a-8def-9c36d6585ea1.jpg",
            //     price: 60,
            // },
            // {
            //     id: 3,
            //     name: 'Роза эквадор красная',
            //     quantity: 149,
            //     isStock: 2,
            //     measure: '1 шт',
            //     url: "50984d25-5aee-466a-8def-9c36d6585ea1.jpg",
            //     price: 60,
            // },
            // {
            //     id: 4,
            //     name: 'Роза эквадор красная',
            //     quantity: 149,
            //     isStock: 2,
            //     measure: '1 шт',
            //     url: "50984d25-5aee-466a-8def-9c36d6585ea1.jpg",
            //     price: 60,
            // },
            // {
            //     id: 5,
            //     name: 'Роза эквадор красная',
            //     quantity: 149,
            //     isStock: 2,
            //     measure: '1 шт',
            //     url: "50984d25-5aee-466a-8def-9c36d6585ea1.jpg",
            //     price: 60,
            // },
            // {
            //     id: 6,
            //     name: 'Роза эквадор красная',
            //     quantity: 149,
            //     isStock: 2,
            //     measure: '1 шт',
            //     url: "50984d25-5aee-466a-8def-9c36d6585ea1.jpg",
            //     price: 60,
            // },
            // {
            //     id: 7,
            //     name: 'Роза эквадор красная',
            //     quantity: 149,
            //     isStock: 2,
            //     measure: '1 шт',
            //     url: "50984d25-5aee-466a-8def-9c36d6585ea1.jpg",
            //     price: 60,
            // },
            // {
            //     id: 8,
            //     name: 'Роза эквадор красная',
            //     quantity: 149,
            //     isStock: 2,
            //     measure: '1 шт',
            //     url: "50984d25-5aee-466a-8def-9c36d6585ea1.jpg",
            //     price: 60,
            // },
            // {
            //     id: 9,
            //     name: 'Роза эквадор красная',
            //     quantity: 149,
            //     isStock: 2,
            //     measure: '1 шт',
            //     url: "50984d25-5aee-466a-8def-9c36d6585ea1.jpg",
            //     price: 60,
            // },
            // {
            //     id: 10,
            //     name: 'Роза эквадор красная',
            //     quantity: 149,
            //     isStock: 2,
            //     measure: '1 шт',
            //     url: "50984d25-5aee-466a-8def-9c36d6585ea1.jpg",
            //     price: 60,
            // },
            // {
            //     id: 11,
            //     name: 'Роза эквадор красная',
            //     quantity: 149,
            //     isStock: 2,
            //     measure: '1 шт',
            //     url: "50984d25-5aee-466a-8def-9c36d6585ea1.jpg",
            //     price: 60,
            // },
            // {
            //     id: 12,
            //     name: 'Роза эквадор красная',
            //     quantity: 149,
            //     isStock: 2,
            //     measure: '1 шт',
            //     url: "50984d25-5aee-466a-8def-9c36d6585ea1.jpg",
            //     price: 60,
            // },
            // {
            //     id: 13,
            //     name: 'Роза эквадор красная',
            //     quantity: 149,
            //     isStock: 2,
            //     measure: '1 шт',
            //     url: "50984d25-5aee-466a-8def-9c36d6585ea1.jpg",
            //     price: 60,
            // },
            // {
            //     id: 14,
            //     name: 'Роза эквадор красная',
            //     quantity: 149,
            //     isStock: 2,
            //     measure: '1 шт',
            //     url: "50984d25-5aee-466a-8def-9c36d6585ea1.jpg",
            //     price: 60,
            // },
        ],
        test: [],
        grossCategories:[
            {
                id: 1,
                name: 'Все'
            },
            {
                id: 2,
                name: 'Розы'
            },
            {
                id: 3,
                name: 'Хризантемы'
            },
            {
                id: 4,
                name: 'Альстромерии'
            }
        ],
        userCategories:[],
        grossRemains: [
            // {
            //     id: 12,
            //     name: 'Роза эквадор красная',
            //     quantity: 149,
            //     isStock: 2,
            //     measure: '1 шт',
            //     address: 'Малый В.О. 15',
            //     url: "50984d25-5aee-466a-8def-9c36d6585ea1.jpg",
            //     price: 60,
            // },
            // {
            //     id: 13,
            //     name: 'Роза эквадор красная',
            //     quantity: 149,
            //     isStock: 2,
            //     measure: '1 шт',
            //     address: 'Пражская улица, 3',
            //     url: "50984d25-5aee-466a-8def-9c36d6585ea1.jpg",
            //     price: 60,
            // },
            // {
            //     id: 14,
            //     name: 'Пион',
            //     quantity: 149,
            //     isStock: 2,
            //     measure: '1 шт',
            //     address: 'Малый В.О. 15',
            //     url: "50984d25-5aee-466a-8def-9c36d6585ea1.jpg",
            //     price: 60,
            // },
        ],
        allShops: [
            {
                id: 1,
                name: 'Duty Free Flowers',
                address: 'Московская 27',
                workTime: '10:00 - 22:00',
                pe: 'Иван Петров',
                isPickup: false,
                isDelivery: true
            },
            {
                id: 2,
                name: 'Duty Free Flowers',
                address: 'Московская 27',
                workTime: '10:00 - 22:00',
                pe: 'Иван Петров',
                isPickup: true,
                isDelivery: true
            },
            {
                id: 3,
                name: 'Duty Free Flowers',
                address: 'Московская 27',
                workTime: '10:00 - 22:00',
                pe: 'Иван Петров',
                isPickup: true,
                isDelivery: true
            },
            {
                id: 4,
                name: 'Duty Free Flowers',
                address: 'Московская 27',
                workTime: '10:00 - 22:00',
                pe: 'Иван Петров',
                isPickup: true,
                isDelivery: true
            },
            {
                id: 5,
                name: 'Duty Free Flowers',
                address: 'Московская 27',
                workTime: '10:00 - 22:00',
                pe: 'Иван Петров',
                isPickup: true,
                isDelivery: true
            },
            {
                id: 6,
                name: 'Duty Free Flowers',
                address: 'Московская 27',
                workTime: '10:00 - 22:00',
                pe: 'Иван Петров',
                isPickup: true,
                isDelivery: true
            },
        ],
        favorites: [],
        grossDeliveryAddress: [],
        grossSelectedCategory: 'Все',
        grossIsSelfPickup: false,
        orderSortItems: [
            {name: 'Принят'},
            {name: 'Обработан'},
            {name: 'В пути'},
            {name: 'Доставлен'},
            {name: 'Возврат'},
            {name: 'Отменен'}],
        orderSortSelected: 'Принят',
        partners:[],
        selectedPartner:  {},
        requests:[],
        page: 0,
        cardsPerPage: 9,
        totalPages: 0,
        searchValue: '',
        selectedCategogy: 'Все',
        grossCategory: 'Все',
        orders: '',
        maxPrice: 10000,
        minPrice: 0,
        userLogin: ''
    },
    getters: {
        PRODUCTS(state){
            return state.products;
        },
        TOTAL_PAGES(state){
            return state.totalPages
        },
        CURRENT_PAGE(state){
            return state.page
        },
        SELECTED_CATEGORY(state){
            return state.selectedCategogy
        },
        BURGER_OPENED(state){
            return state.isBurgerOpened
        },
        USER_FAVORITES(state){
            return state.favorites
        },
        PRODUCTS(state){
            return state.products;
        },
        GROSS_PRODUCTS(state){
            return state.grossProducts
        },
        GROSS_CATEGORIES(state){
            return state.grossCategories
        },
        CATEGORIES(state){
            return state.userCategories
        },
        GROSS_REMAINS(state){
            return state.grossRemains
        },
        SELECTED_GROSS_CATEGORY(state){
            return state.grossSelectedCategory
        },
        POPULAR_PRODUCTS(state){
            return state.popularProducts
        },
        SELECTED_PRODUCT(state){
            return state.currentProduct
        },
        GROSS_SELECTED_PRODUCT(state){
            return state.currentGrossProduct
        },
        CART(state){
            return state.cart
        },
        GROSS_CART(state){
            return state.grossCart
        },
        MAIN_PHOTO(state){
            return state.mainProductPhoto
        },
        GROSS_MAIN_PHOTO(state){
            return state.mainGrossProductPhoto
        },
        GROSS_SHOPS(state){
            return state.grossShops
        },
        GROSS_IS_PICKUP(state){
            return state.grossIsSelfPickup
        },
        ALL_SHOPS(state){
            return state.allShops
        },
        ORDER_SORT(state){
            return state.orderSortItems
        },
        SELECTED_ORDER_SORT(state){
            return state.orderSortSelected
        },
        PARTNERS(state){
            return state.partners
        },
        SELECTED_PARTNER(state){
            return state.selectedPartner
        },
        REQUESTS(state){
            return state.requests
        },
        ORDERS(state){
            return state.orders
        },
        USER_LOGIN(state){
            return state.userLogin
        }
    },
    mutations: {
        SET_PRODUCTS_TO_STATE: (state, products)=>{
            state.products = products.rows
        },
        SET_MORE_PRODUCTS: (state, products)=>{
            state.products = state.products.concat(products.rows)
        },
        SET_SELECTED_CATEGORY: (state, category)=>{
            state.selectedCategogy = category
        },
        SET_ONE_PRODUCT: (state, data)=>{
            state.currentProduct = data
            state.mainProductPhoto = data.url
        },
        SET_ONE_GROSS_PRODUCT: (state, data)=>{
            let product = {
                cartQty: 1,
                price: 0
            }
            product.price = data.salePrices[0].value / 100

            Object.assign(data, product)
            
            state.currentGrossProduct = data
            state.mainGrossProductPhoto = data.url
        },
        SET_MAIN_PHOTO: (state, url)=>{
            state.mainProductPhoto = url
        },
        SET_GROSS_MAIN_PHOTO: (state, url)=>{
            state.mainGrossProductPhoto = url
        },
        SET_CART: (state, product)=>{
            if(state.cart.length){
                let isProductExist = false;
                state.cart.map(function(item){
                    if(item.id === product.id){
                        isProductExist = true;
                        item.quantity++
                    }
                })
                if(!isProductExist){
                    state.cart.push(product)
                }
            }else{
                state.cart.push(product)
            }
        },
        SET_GROSS_CART: (state, product)=>{
            if(state.grossCart.length){
                let isProductExist = false;
                state.grossCart.map(function(item){
                    if(item.id === product.id){
                        isProductExist = true;
                        item.quantity++
                    }
                })
                if(!isProductExist){
                    state.grossCart.push(product)
                }
            }else{
                state.grossCart.push(product)
            }
        },
        REMOVE_FROM_CART: (state, index)=>{
            state.cart.splice(index, 1)
        },
        INCREASE: (state, index)=>{
            state.cart[index].quantity++
        },
        DECREASE: (state, index)=>{
            if (state.cart[index].quantity > 1){
                state.cart[index].quantity--
            }else{
                state.cart.splice(index, 1)
            }
        },
        REMOVE_FROM_GROSS_CART: (state, index)=>{
            state.grossCart.splice(index, 1)
        },
        GROSS_CART_INCREASE: (state, index)=>{
            state.grossCart[index].cartQty++
            // state.grossCart[index].remains++
        },
        GROSS_CART_DECREASE: (state, index)=>{
            if (state.grossCart[index].cartQty > 1){
                state.grossCart[index].cartQty--
                // state.grossCart[index].remains--
            }else{
                state.grossCart.splice(index, 1)
            }
        },
        GROSS_CART_CHANGE_QTY: (state, obj) => {
            state.grossCart[obj.index].cartQty = obj.qty
        },
        SET_SELECTED_CATEGORY: (state, category)=>{
            state.grossSelectedCategory = category
        },
        SET_GROSS_DELIVERY_TYPE: (state, isSelf)=>{
            state.grossIsSelfPickup = isSelf
        },
        SET_SELECTED_ORDER_SORT: (state, item)=>{
            state.orderSortSelected = item
        },
        SET_SELECTED_PARTNER: (state, item)=>{
            state.selectedPartner = item
        },
        SET_GROSS_PRODUCTS: (state, products)=>{
            state.grossProducts = products
        },
        SET_GROSS_SHOPS: (state, shops)=>{
            let formatedShops = []
            shops.forEach((item)=>{
                item.cart = JSON.parse(item.cart)
                formatedShops.push(item)
            })
            state.grossShops = formatedShops
        },
        SET_DISCOUNT: (state, discount)=>{
            state.grossDiscount = discount
        },
        TOGGLE_BURGER: (state)=>{
            state.isBurgerOpened = !state.isBurgerOpened 
        },
        SET_TOTAL_PAGES: (state, value)=>{
            state.totalPages = Math.ceil(value / state.cardsPerPage)
        },
        REMOVE_PRODUCTS: (state)=>{
            state.products = []
        },
        SET_DEFAULT_PAGE_NUMBER: (state, value)=>{
            state.page = value
        },
        SET_ORDERS_TO_STATE: (state, orders)=>{
            state.orders = orders
        },
        SET_POPULAR_PRODUCTS: (state, products)=>{
            state.popularProducts = products
        },
        REMOVE_SEARCH_VALUE: (state)=>{
            state.searchValue = ''
        },
        SET_CLIENT_CATEGORY: (state, val)=>{
            state.selectedCategogy = val
        },
        SET_SORT_DIRECTION:(state, direction)=>{
            state.sortDirection = direction
        },
        SET_MIN_VALUE: (state, minVal)=>{
            state.minPrice = minVal
        },
        SET_MAX_VALUE: (state, maxVal)=>{
            state.maxPrice = maxVal
        },
        SET_SEARCH_VALUE: (state, value) => {
            state.searchValue = value
        },
        SET_USER_LOGIN:(state, login)=>{
            state.userLogin = login
        },
        SET_GROSS_REMAINS: (state, remains)=>{
            state.grossRemains = remains
        },
        REMOVE_SEARCH_VALUE: (state)=>{
            state.searchValue = ''
        },
        SET_CATEGORIES: (state, categories)=>{
            state.userCategories = categories
        },
    },
    actions: {
        GET_MORE_PRODUCTS({commit}){
            if(this.state.searchValue != ''){
                return axios({
                    url: 'https://cvetprice.ru:3000/api/goods/search',
                    method: "GET",
                    params:{
                        searchVal: this.state.searchValue,
                        page: ++this.state.page
                    } 
                }).then((products)=>{
                    commit('SET_MORE_PRODUCTS', products.data);
                    commit('SET_TOTAL_PAGES', products.data.count);
                })
            }
            if(this.state.selectedCategogy !=='Все'){
                return axios({
                    url: 'https://cvetprice.ru:3000/api/goods/sort',
                    method: "GET",
                    params:{
                        category: this.state.selectedCategogy,
                        subcategory: this.state.categoryValue,
                        dir: this.state.sortDirection,
                        page: ++this.state.page,
                        maxVal: this.state.maxPrice,
                        minVal: this.state.minPrice,
                    }
                }).then((products)=>{
                    commit('SET_MORE_PRODUCTS', products.data);
                    commit('SET_TOTAL_PAGES', products.data.count);
                })
            }
            return axios(`https://cvetprice.ru:3000/api/goods/flowers/all`,{
                method: "GET",
                params:{
                    page: ++this.state.page,
                    dir: this.state.sortDirection,
                    maxVal: this.state.maxPrice,
                    minVal: this.state.minPrice,
                }
                }).then((products)=>{
                    commit('SET_MORE_PRODUCTS', products.data);
                    commit('SET_TOTAL_PAGES', products.data.count);
                }).catch()
        },
        GET_CATEGORIES({commit}){
            return axios('https://cvetprice.ru:3000/api/userCategory/all',
            {
                method: "GET",
            }).then((categories)=>{
                commit('SET_CATEGORIES', categories.data);
            })
        },
        GET_PROD_BY_RANGE({commit}){
            return axios(`https://cvetprice.ru:3000/api/goods/flowers/all`,{
                method: "GET",
                params:{
                    page: 1,
                    dir: this.state.sortDirection,
                    maxVal: this.state.maxPrice,
                    minVal: this.state.minPrice,
                }
                }).then((products)=>{
                    commit('SET_PRODUCTS_TO_STATE', products.data);
                    commit('SET_TOTAL_PAGES', products.data.count);
                }).catch()
        },
        GET_POPULAR_PRODUCTS({commit}){
            return axios({
                url: 'https://cvetprice.ru:3000/api/goods/flowers/popular',
                method: "GET"
            }).then((products)=>{
                commit('SET_POPULAR_PRODUCTS', products.data);
            })
        },
        // ОСТАТКИ
        GET_GROSS_REMAINS({commit}){
            return axios({
                url: 'https://cvetprice.ru:3000/api/goods/grossFlowers',
                method: "GET",
                params: {
                    category: this.state.selectedCategogy
                }
            }).then((products)=>{
                commit('SET_GROSS_REMAINS', products.data.rows);
            })
        },
        GET_ONE_PRODUCT({commit}, data){
            commit('SET_ONE_PRODUCT', data)
        },
        GET_ONE_GROSS_PRODUCT({commit}, data){
            commit('SET_ONE_GROSS_PRODUCT', data)
        },
        SELECT_CATEGORY({commit}, value){
            commit('SET_CLIENT_CATEGORY', value)
        },
        ADD_TO_CART({commit}, product){
            commit('SET_CART', product);
        },
        INCREASE_QTY({commit}, index){
            commit('INCREASE', index)
        },
        DECREASE_QTY({commit}, index){
            commit('DECREASE', index)
        },
        DELETE_FROM_CART({commit}, index){
            commit('REMOVE_FROM_CART', index)
        },
        ADD_TO_GROSS_CART({commit}, product){
            commit('SET_GROSS_CART', product);
        },
        INCREASE_GROSS_CART_QTY({commit}, index){
            commit('GROSS_CART_INCREASE', index)
        },
        DECREASE_GROSS_CART_QTY({commit}, index){
            commit('GROSS_CART_DECREASE', index)
        },
        CHANGE_GROSS_CART_QTY({commit}, obj){
            commit('GROSS_CART_CHANGE_QTY', obj)
        },
        DELETE_FROM_GROSS_CART({commit}, index){
            commit('REMOVE_FROM_GROSS_CART', index)
        },
        SELECT_MAIN_PHOTO({commit}, url){
            commit('SET_MAIN_PHOTO', url)
        },
        SELECT_GROSS_MAIN_PHOTO({commit}, url){
            commit('SET_GROSS_MAIN_PHOTO', url)
        },
        CHANGE_GROSS_DELIVERY_TYPE({commit}, isSelf){
            commit('SET_GROSS_DELIVERY_TYPE', isSelf)
        },
        SELECT_ORDER_SORT({commit}, item){
            commit('SET_SELECTED_ORDER_SORT', item)
        },
        SELECT_PARTNER({commit}, item){
            commit('SET_SELECTED_PARTNER', item)
        },
        GET_GROSS_PRODUCTS({commit}){
            // axios({
            //     method: "GET",
            //     url: "https://lepestok-24.ru:3000/api/goods/test",
            // }).then((response)=>{
            //     commit('SET_GROSS_PRODUCTS', response.data)
            // })
        },
        GET_GROSS_DATA({commit}){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios({
                method: "GET",
                url: "https://cvetprice.ru:3000/api/grossUser/userdata",
                headers: {Authorization: `Bearer ${cookie[1]}`},
            }).then((response)=>{
                commit('SET_DISCOUNT', response.data.discount)
            })
        },
        GET_GROSS_SHOPS({commit}){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios({
                method: "GET",
                url: "https://cvetprice.ru:3000/api/shop/all",
                headers: {Authorization: `Bearer ${cookie[1]}`},
            }).then((response)=>{
                commit('SET_GROSS_SHOPS', response.data)
            })
        },
        CHANGE_BURGER({commit}){
            commit('TOGGLE_BURGER')
        },
        RESET_PAGE_NUMBER({commit}){
            if(this.state.page >= 1){
                commit('REMOVE_PRODUCTS')
            }
            commit('SET_DEFAULT_PAGE_NUMBER', 0)
        },
        GET_SEARCH_VALUE({commit}, value){
            commit('SET_SEARCH_VALUE', value)
        },
        GET_ORDERS({commit}){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios.get('https://cvetprice.ru:3000/api/orders/orders-by-admin',
            {headers: {Authorization: `Bearer ${cookie[1]}`}
            }).then((res)=>{
                commit('SET_ORDERS_TO_STATE', res.data.rows)
                // this.orders = res.data.rows
            })
        },
        GET_SORTED_ORDERS({commit}, status){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios.get('https://cvetprice.ru:3000/api/orders/workstatus', 
            {headers: {Authorization: `Bearer ${cookie[1]}`},
            params:{
                workstatus: status
            }}).then((res)=>{
                commit('SET_ORDERS_TO_STATE', res.data.rows)
            })
        },
        RESET_SEARCH_VALUE({commit}){
            commit('REMOVE_SEARCH_VALUE')
        },
        GET_SORT_DIRECTION({commit}, direction){
            commit('SET_SORT_DIRECTION', direction)
        },
        GET_MAX_VALUE({commit}, maxVal){
            commit('SET_MAX_VALUE', maxVal)
        },
        GET_MIN_VALUE({commit}, minVal){
            commit('SET_MIN_VALUE', minVal)
        },
        GET_USER_LOGIN({commit}, login){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            if (cookie) {
                commit('SET_USER_LOGIN', cookie)
            }
        },
        RESET_SEARCH_VALUE({commit}){
            commit('REMOVE_SEARCH_VALUE')
        },
        // OPEN_BURGER({commit}){
        //     commit('TOGGLE_BURGER', true)
        // },
        // CLOSE_BURGER({commit}){
        //     commit('TOGGLE_BURGER', false)
        // }
    }
})
